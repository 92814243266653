import { getQueryParams } from 'app/utils/queryParams';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';
import { RegimeLiteral, StackedBarChartConfig } from 'app/utils/types';
import { themeColors } from 'app/utils/colors';
import { DotDecoration, Popover } from 'app/components/common';
import { getWording } from 'app/constants/wording/wording';
import { useTransformCurrency as r, withPrefix } from 'app/utils/hooks/useTransformCurrency';
import { useCurrencyContext } from 'app/contexts/CurrencyContext';
import { getPercentage } from 'app/utils/charts';
import { getIcon } from 'app/utils/staticStorage';
import { formatPercentage } from 'app/utils/formatters';
import { ProvisionalSavingsPayload, provisionalSavingsService } from './provisionalSavingsService';

export function useProvisionalSavings() {
  const [showEmptyContent, setShowEmptyContent] = useState<boolean>(false);
  const { is_empty, ...payloadParameters } = getQueryParams<ProvisionalSavingsPayload>();
  const { wording } = getWording();
  const { handleInitCurrencyData } = useCurrencyContext();

  const {
    data: simulationResult,
    mutate: dispatchProvisionalSavingsSimulation,
    isError,
    isLoading,
    error,
  } = useMutation({
    mutationFn: async (payload: ProvisionalSavingsPayload) => (await provisionalSavingsService
      .simulate(payload)).data,

    onSuccess: (data) => {
      const { currency, uf_value } = data;
      handleInitCurrencyData(currency, uf_value);
    },
  });

  const chartData = useMemo(() => {
    if (!simulationResult) {
      return null;
    }

    const {
      personal_savings_without_interest,
      fiscal_contribution_without_interest,
      savings_expected_return,
      total_afp_savings,
    } = simulationResult;

    const total = personal_savings_without_interest
    + fiscal_contribution_without_interest
    + savings_expected_return
    + total_afp_savings;

    const chartConfig: StackedBarChartConfig = {
      name: 'Provisional savings',
      stacks: [
        {
          field: 'savings',
          color: themeColors.orange,
          value: getPercentage(total_afp_savings, total),
          topLabel: () => <div>💵</div>,
          label: (color) => (
            <div className="fs-small">
              <div className="d-flex align-items-center gap-1">
                <DotDecoration size={8} color={color} />
                <span className="fw-bold d-block" style={{ color }}>{r(total_afp_savings, withPrefix)}</span>
              </div>

              <div className="fs-smaller text-dark">
                {`Es ${wording.your} ahorro en ${wording.your} cuenta obligatoria`}
              </div>
            </div>
          )
          ,
        },
        {
          field: 'afp',
          color: themeColors.info,
          value: getPercentage(personal_savings_without_interest, total),
          topLabel: () => <div>💰</div>,
          label: (color) => (
            <div className="fs-small">
              <div className="d-flex align-items-center gap-1">
                <DotDecoration size={8} color={color} />
                <span className="fw-bold d-block" style={{ color }}>{r(personal_savings_without_interest, withPrefix)}</span>
              </div>
              <div className="fs-smaller text-dark">
                {`Es ${wording.your} ahorro en APV`}
              </div>
            </div>
          ),
        },
        {
          field: 'bonuses',
          color: themeColors.primary,
          value: getPercentage(fiscal_contribution_without_interest, total),
          topLabel: () => <div>🎁</div>,
          label: (color) => {
            const labelsMap: Record<Uppercase<RegimeLiteral>, JSX.Element> = {
              A:
              (
                <span>
                  Bonificación fiscal y/o beneficio tributario
                </span>
              ),
              B: (
                <div className="text-balance">
                  {`Es ${wording.your} beneficio tributario `}
                  <Popover
                    id="bonuses-percentage-popover"
                    renderTrigger={() => <img width={10} height={10} src={getIcon('help.svg')} alt="Más información" />}
                    trigger={['hover', 'focus']}
                    width={362}
                    placement="bottom"
                    renderContent={() => (
                      <div className="d-flex align-items-start gap-2 p-3">
                        <img width={48} src={getIcon('help-light.svg')} alt="" />

                        <section className="d-flex flex-column gap-1 text-white">
                          <header>
                            <h4 className="fs-6 fw-bold mb-0">¡Atención!</h4>
                          </header>

                          <p className="mb-0">
                            <span>{'El '}</span>
                            <span className="fw-bold">{'beneficio tributario '}</span>
                            <span>{'corresponde al '}</span>
                            <span className="fw-bold">
                              {`${formatPercentage(
                                Number(
                                  getPercentage(
                                    fiscal_contribution_without_interest,
                                    personal_savings_without_interest
                                    + fiscal_contribution_without_interest
                                  )
                                    .toFixed(0)),
                                { withTransform: true }
                              )} `}
                            </span>
                            <span>de tu aporte</span>
                          </p>
                        </section>
                      </div>
                    )}
                  />
                </div>
              ),
            };

            return (
              <div className="fs-small">
                <div className="d-flex align-items-center gap-1">
                  <DotDecoration size={8} color={color} />
                  <span className="fw-bold d-block" style={{ color }}>{r(fiscal_contribution_without_interest, withPrefix)}</span>
                </div>

                <div className="fs-smaller text-dark">
                  {labelsMap[payloadParameters.apv_regime]}
                </div>
              </div>
            );
          },
        },
        {
          field: 'rentability',
          color: themeColors.secondary,
          value: getPercentage(savings_expected_return, total),

          topLabel: () => <div>📈</div>,
          label: (color) => (
            <div className="fs-small">
              <div className="d-flex align-items-center gap-1">
                <DotDecoration size={8} color={color} />
                <span className="fw-bold" style={{ color }}>{r(savings_expected_return, withPrefix)}</span>
              </div>

              <div className="fs-smaller text-dark">
                {`Lo que ${wording.couldWin} ganar por rentabilidad`}
              </div>
            </div>
          ),
        },
      ],
    };

    return chartConfig;
  }, [simulationResult]);

  useEffect(() => {
    if (is_empty) {
      setShowEmptyContent(Boolean(is_empty));
      return;
    }

    dispatchProvisionalSavingsSimulation(payloadParameters);
  }, []);

  return {
    isError,
    isLoading,
    error,
    simulationResult,
    chartData,
    isEmpty: showEmptyContent,
  };
}
